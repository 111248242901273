
export default {
    namespaced: true,
    state: () => ({
        makesMostChosen: ['AUDI','VOLKSWAGEN','SEAT','SKODA','PORSCHE']
    }),
    getters: {
        mostChosenMakes: state => {
            return state.makesMostChosen
        },
    },
    mutations: {
        setMostChosenMakes: (state, data) => {
            state.makesMostChosen = data;
        },
    }
}