import axios from "axios";
import store from '@/store';


export default {
    namespaced: true,
    state: () => ({
        lead: null,
        leadId: null,
        loadingLead: false,
        part: 'start',
        menu: 'customer'
    }),
    getters: {
        loading: state => {
            return state.loadingLead;
        },
        lead: state => {
            return state.lead;
        },
        part: state => {
            return state.part;
        },
        menu: state => {
            return state.menu;
        },
        buyerActive: state => {
            let customer = state.lead.buyer;
            console.log('here');
            if(customer===undefined||customer===null) return true;
            if(customer.manual===undefined||customer.manual===null) customer.manual=false;
            if(customer.manual) return false;
            if ((customer.lastName === undefined || customer.lastName === null || customer.lastName === '') &&
                (customer.companyName === undefined || customer.companyName === null || customer.companyName === '')) return true;
            return false;
        },
    },
    actions: {
    },
    mutations: {
        setPart: (state, data) => {
            state.part = data;
        },
        setMenu: (state, data) => {
            state.menu = data;
        },
        setLoading: (state, data) => {
          state.loadingLead = data;
        },
        openItem: (state, data) => {
            state.loadingLead = true;
            state.leadId = data;
            store.commit('master/openDetail');
            axios.get('/manager/seller/data/request/' + state.leadId + '/').then(response => {
                state.lead = response.data;
                state.loadingLead = false;
            }).catch(function (error) {
                console.log(error);
            });
        },
        setCustomer: (state, data) => {
            state.lead.buyer = data;
        }
    }
}