import en from './en.json'
import fr from './fr.json'
import nl from './nl.json'
import de from './de.json'


export default {
    en: en['en'],
    fr: fr['fr'],
    nl: nl['nl'],
    de: de['de']
}
