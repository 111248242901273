<template>
    <component :is="layout">
        <slot/>
    </component>
</template>

<script>
const defaultLayout = 'LayoutDefault'
const defaultApp = 'master'

export default {
    name: "Layout",
    computed: {
        layout() {
            let app = (this.$route.meta.app || defaultApp) + '/'
            const layout = this.$route.meta.layout || defaultLayout
            return () => import(`@/${app}layouts/${layout}.vue`)
        }
    }
}
</script>

