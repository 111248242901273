
export default {
    namespaced: true,
    state: () => ({
        part: 'start',
        menu: 'customer',
        savable: false,
        refreshable: false,
        vin: '',
        versions: [],
        makeIds: [],
        commercial: false,
        modelIds: [],
        version: null,
        registration: null,
        vehicleItems: null,
        features: null,
        damageData: null,
        customerSearch: null,
        customer: null
    }),
    getters: {
        part: state => {
            return state.part
        },
        vin: state => {
            return state.vin
        },
        version: state => {
            return state.version
        },
        versions: state => {
            return state.versions
        },
        makes: state => {
            return  state.makeIds
        },
        models: state => {
            return  state.modelIds
        },
        registration: state => {
            return  state.registration
        },
        commercial: state => {
            return  state.commercial
        },
        vehicleItems: state => {
            return state.vehicleItems
        },
        features: state => {
            return state.features
        },
        damageData: state => {
            return state.damageData
        },
        menu: state => {
            return state.menu
        },
        customerSearch: state => {
            return state.customerSearch
        }
    },
    mutations: {
        setPart: (state, data) => {
            state.part = data;
        },
        setVin: (state, data) => {
            state.vin = data;
        },
        setMakes: (state, data) => {
            state.makeIds = data
        },
        setModels: (state, data) => {
            state.modelIds = data
        },
        setCommercial: (state, data) => {
            state.commercial = data
        },
        setVersions: (state, data) => {
            state.versions = data;
        },
        setRegistration: (state, data) => {
            state.registration = data;
        },
        setVehicleItems: (state, data) => {
            state.vehicleItems = data;
        },
        setMenu: (state, data) => {
            state.menu = data
        },
        setFeatures: (state, data) => {
            state.features = data
        },
        setDamageData: (state, data) => {
            state.damageData = data
        },
        setCustomerSearch: (state, data) => {
            state.customerSearch = data
        }
    }
}