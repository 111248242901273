import axios from "axios";
import store from '@/store';
import {nil} from "@/common";

export default {
    namespaced: true,
    state: () => ({
        lead: null,
        leadId: null,
        version: null,
        loadingLead: false
    }),
    getters: {
        loading: state => {
            return state.loadingLead;
        },
        lead: state => {
            return state.lead;
        },
        customerActive: state => {
            let customer = state.lead.customer;
            if(customer===undefined||customer===null) return true;
            if(customer.manual===undefined||customer.manual===null) customer.manual=false;
            if(customer.manual) return false;
            if ((customer.lastName === undefined || customer.lastName === null || customer.lastName === '') &&
                (customer.companyName === undefined || customer.companyName === null || customer.companyName === '')) return true;
            return false;
        },
        header: state => {
            if(state.lead==null || state.lead.leadId === null || state.lead.leadId === 0) {
                return {
                    primaryActionTitle: 'Save',
                    secondaryActionTitle: '',
                    customerPrimary: '',
                    customerSecondary: '',
                    title: 'New trade in',
                    state: 'New',
                    description: '',
                    reference: ''
                }
            }
            let s_primary = state.lead == null ? '' : `${nil(state.lead.customer.lastName)} ${nil(state.lead.customer.firstName)}`;
            if(s_primary.trim()=='') s_primary = nil(state.lead.customer.companyName);
            let s_secondary = `${nil(state.lead.customer.postCode)} ${nil(state.lead.customer.city)}`;
            if(s_secondary.trim()==="") s_secondary = `${nil(state.lead.customer.companyPostCode)} ${nil(state.lead.customer.companyCity)}`;
            return {
                primaryActionTitle: 'Save',
                secondaryActionTitle: '',
                customerPrimary: s_primary,
                customerSecondary: s_secondary,
                title: state.lead == null ? '' : `${state.lead.condition.car.makeText} ${state.lead.condition.car.modelText}`,
                state: state.lead.state,
                description: state.lead.condition.car.versionText,
                reference: state.lead.leadId,
            }
        }
    },
    actions: {
        selectVersion({ commit }, version) {
            let s_etx_firstuse = "";
            if(version.firstRegistrationDate!==undefined&&version.firstRegistrationDate!==null) {
                s_etx_firstuse = version.firstRegistrationDate.substr(0, 4) + version.firstRegistrationDate.substr(5, 2) + version.firstRegistrationDate.substr(8, 2);
            } else {
                version.buildDate = nil(version.buildDate)
                s_etx_firstuse = version.buildDate.substr(0, 4) + version.buildDate.substr(5, 2) + version.buildDate.substr(8, 2);
            }
            store.commit('seller/setLoading', true);
            axios.get('/manager/fleet/eurotax/detail/' +  version.vehicleTypeId + '/' + version.typeId + '/' +  s_etx_firstuse + '/?vin=' + version.vin).then(response => {
                if(response.data.length<1) {
                    store.commit('tradein/setPart', 'error');
                    return;
                }
                commit('setVersion', response.data[0]);
                store.commit('seller/setLoading', false);
            }).catch(function (error) {
                store.commit('tradein/setPart', 'error');
                console.log(error);
            });
        }
    },
    mutations: {
        setLoading: (state, data) => {
          state.loadingLead = data;
        },
        openItem: (state, data) => {
            state.loadingLead = true;
            state.leadId = data;
            store.commit('master/openDetail');
            axios.get('/manager/seller/data/request/' + state.leadId + '/').then(response => {
                state.lead = response.data;
                state.loadingLead = false;
                if(!state.lead.condition.car.makeText) {
                    //Car bran/model/version  not defined start at base
                    store.commit('tradein/setPart', 'start');
                } else {
                    store.commit('tradein/setPart', 'vehicle-detail');
                }
            }).catch(function (error) {
                console.log(error);
            });
        },
        newItem: (state) => {
            state.loadingLead = true;
            state.leadId = 0;
            store.commit('master/openDetail');
            axios.get('/manager/seller/data/request/' + state.leadId + '/').then(response => {
                state.lead = response.data;
                state.loadingLead = false;
                store.commit('tradein/setPart', 'vehicle-detail');
            }).catch(function (error) {
                console.log(error);
            });
        },
        setVersion: (state, data)  => {
            state.version = data;
            state.lead.condition.car.vin = data.vin;
            state.lead.condition.car.doorCount = data.doorCount;
            state.lead.condition.car.seatCount = data.seatCount;
            state.lead.condition.car.makeText = data.makeText;
            state.lead.condition.car.modelText = data.modelText;
            state.lead.condition.car.versionText = data.versionText;
            state.lead.condition.car.firstRegistrationDate = null;
            if(data.firstRegistrationDate!==undefined&&data.firstRegistrationDate!==null)
                state.lead.condition.car.firstRegistrationDate = data.firstRegistrationDate;
            state.lead.condition.car.powerHp = data.powerHp;
            state.lead.condition.car.powerKw = data.powerKw;
            state.lead.condition.car.powerFiscal = data.powerFiscal;
            state.lead.condition.car.weightCapacity = data.maxWeight;
            state.lead.condition.car.wheelbase = data.wheel_base;
            state.lead.condition.car.emissionNormId = null;
            state.lead.condition.car.emissionCO2 = data.emissionCO2;
            state.lead.condition.car.colorExteriorId = 16; //TODO
            state.lead.condition.car.colorExteriorText = "";
            if(data.originColorExterior!==undefined&&data.originColorExterior!==null)
                state.lead.condition.car.colorExteriorText = data.originColorExterior;
            state.lead.condition.car.colorInteriorId = 16; //TODO
            state.lead.condition.car.colorInteriorText = "";
            state.lead.condition.car.coveringInteriorId = 4; //TODO
            state.lead.condition.car.emissionNormId = 13; //TODO
            state.lead.condition.car.coveringInteriorText = "";
            if(data.originColorInterior!==undefined&&data.originColorInterior!==null)
                state.lead.condition.car.colorInteriorText = data.originColorInterior;
            if(data.autralis) {
                state.lead.condition.car.bodyTypeId = data.autralis.bodyId;
                state.lead.condition.car.fuelTypeId = data.autralis.fuelId;
                state.lead.condition.car.transmissionId = data.autralis.transmissionId;
                state.lead.condition.car.driveTypeId = data.autralis.driveId;
            }
            store.commit('tradein/setPart', 'vehicle-detail');
        },
        setCustomer: (state, data) => {
            state.lead.customer = data;
        }
    }
}