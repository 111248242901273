import axios from "axios";
import router from "@/router";

export default {
    namespaced: true,
    state: () => ({
        authenticated: false,
        config: null,
        user: null,
        company: null,
        unit: null,
        brand: null,
        applications: [],
        application: null,
        detailOpen: false,
        dashboard: null,
        detail: null,
        companyAddresses: [],
        totalCompanyAddresses: null,
        suppliers: [],
        totalSuppliers: null,
        selectedSupplier: null,

        businessUnits: [],
        totalBusinessUnits: null,
        selectedBusinessUnit: null,

        businessUnitGroups: [],
        totalBusinessUnitGroups: null,
        selectedBusinessUnitGroup: null,

        messages: [],
        selectedMessage: null,
        selectedMessagesInboxType: null,
        totalNewMessages: null,
        news: [],
        selectedNews: null,
        companyUsers: [],
        totalCompanyUsers: null,
        countries: []
    }),
    getters: {
        isAuthenticated: state => {
            return state.authenticated
        },
        isAuthenticatedFull: state => {
            return state.authenticated && state.company !== null && state.unit !== null && state.brand !== null;
        },
        isStaff: state => {
            return state.user.is_staff;
        },
        companies: state => {
            if (state.config === undefined || state.config === null) return [];
            return state.config.forCompanies;
        },
        units: state => {
            return state.company.units;
        },
        brands: state => {
            return state.unit.brands;
        },
        company: state => {
            return state.company;
        },

        companyAddresses: state => {
            return state.companyAddresses;
        },

        totalCompanyAddresses: state => {
            return state.totalCompanyAddresses;
        },

        suppliers: state => {
            return state.suppliers;
        },

        selectedSupplier: state => {
            return state.selectedSupplier;
        },

        totalBusinessUnits: state => {
            return state.totalBusinessUnits;
        },

        businessUnits: state => {
            return state.businessUnits;
        },

        selectedBusinessUnit: state => {
            return state.selectedBusinessUnit;
        },

        totalBusinessUnitGroups: state => {
            return state.totalBusinessUnitGroups;
        },

        businessUnitGroups: state => {
            return state.businessUnitGroups;
        },

        selectedBusinessUnitGroup: state => {
            return state.selectedBusinessUnitGroup;
        },

        totalSuppliers: state => {
            return state.totalSuppliers;
        },

        messages: state => {
            return state.messages;
        },

        totalNewMessages: state => {
            return state.totalNewMessages;
        },

        selectedMessage: state => {
            return state.selectedMessage;
        },

        selectedMessagesInboxType: state => {
            return state.selectedMessagesInboxType;
        },

        news: state => {
            return state.news;
        },

        selectedNews: state => {
            return state.selectedNews;
        },

        companyUsers: state => {
            return state.companyUsers;
        },

        totalCompanyUsers: state => {
            return state.totalCompanyUsers;
        },

        countries: state => {
            return state.countries
        },

        unit: state => {
            return state.unit;
        },
        brand: state => {
            return state.brand;
        },
        user: state => {
            return state.user;
        },
        applications: state => {
            return state.applications
        },
        application: state => {
            return state.application
        },
        detailOpen: state => {
            return state.detailOpen
        },
        subMenu: state => {
            // eslint-disable-next-line no-prototype-builtins
            if (!state.application.hasOwnProperty('subMenu')) {
                state.application.subMenu = state.application.subMenus[0];
            }
            return state.application.subMenu;
        },
        dashboard: state => {
            return state.dashboard;
        },
        detail: state => {
            return state.detail;
        }
    },
    mutations: {
        openDetail: (state) => {
            state.detailOpen = true
        },
        closeDetail: (state) => {
            state.detailOpen = false
        },
        setDashboard: (state, data) => {
            state.dashboard = data;
        },
        setDetail: (state, data) => {
            state.detail = data;
        },
        setUser: (state, newUser) => {
            state.user = {...state.user, ...newUser}
        },

        setCountries: (state, countries) => {
            state.countries = countries
        },

        setAuthenticated: (state, data) => {
            state.authenticated = true;
            state.config = data;
            state.user = data.user;
            state.company = null;
            state.unit = null;
            state.brand = null;
        },
        setCompany: (state, data) => {
            state.company = data;
            state.unit = null;
            state.brand = null;
        },

        setCompanyAddresses: (state, data) => {
            state.companyAddresses = data;
        },

        setTotalCompanyAddresses: (state, data) => {
            state.totalCompanyAddresses = data;
        },

        setSuppliers: (state, data) => {
            state.suppliers = data;
        },

        setSelectedSupplier: (state, data) => {
            state.selectedSupplier = data;
        },

        setBusinessUnits: (state, data) => {
            state.businessUnits = data;
        },

        setSelectedBusinessUnit: (state, data) => {
            state.selectedBusinessUnit = data;
        },

        setTotalBusinessUnits: (state, data) => {
            state.totalBusinessUnits = data;
        },

        setBusinessUnitGroups: (state, data) => {
            state.businessUnitGroups = data;
        },

        setSelectedBusinessUnitGroup: (state, data) => {
            state.selectedBusinessUnitGroup = data;
        },

        setTotalBusinessUnitGroups: (state, data) => {
            state.totalBusinessUnitGroups = data;
        },

        setSelectedMessage: (state, data) => {
            state.selectedMessage = data;
        },

        setTotalNewMessages: (state, data) => {
            state.totalNewMessages = data;
        },

        setSelectedMessagesInboxType: (state, data) => {
            state.selectedMessagesInboxType = data;
        },

        setMessages: (state, data) => {
            state.messages = data;
        },

        setNews: (state, data) => {
            state.news = data;
        },

        setSelectedNews: (state, data) => {
            state.selectedNews = data;
        },

        setTotalSuppliers: (state, data) => {
            state.totalSuppliers = data;
        },

        setCompanyUsers: (state, data) => {
            state.companyUsers = data;
        },

        setTotalCompanyUsers: (state, data) => {
            state.totalCompanyUsers = data;
        },

        setUnit: (state, data) => {
            state.unit = data;
            state.brand = null;
        },
        setBrand: (state, data) => {
            state.brand = data;
        },
        setSubMenu: (state, data) => {
            let app = Object.assign({}, state.application);
            app.subMenu = data;
            state.application = app;
            router.push('/' + app.subMenu.applicationName + '/' + app.subMenu.methodName);
        },
        logOut: (state) => {
            axios.post('/manager/api/v1/logout/', {}, {withCredentials: true});
            state.applications = [];
            state.detailOpen = false;
            state.authenticated = false;
            state.config = null;
            state.company = null;
            state.unit = null;
            state.brand = null;
        },
        setApplication: (state, data) => {
            state.application = data;
            state.application.subMenu = state.application.subMenus[0];
            state.subMenu = state.application.subMenu;
            router.push('/' + state.subMenu.applicationName + '/' + state.subMenu.methodName);
        },
        setApplications: (state, data) => {
            state.applications = data
            state.application = state.applications[0];
            state.application.subMenu = state.application.subMenus[0];
            state.subMenu = state.application.subMenu;
            router.push('/' + state.subMenu.applicationName + '/' + state.subMenu.methodName);
        }
    }
}