import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import master from "@/master/store";
import seller from "@/seller/store";
import sales from "@/sales/store/sales";
import stock from "@/sales/store/stock";
import tradein from "@/seller/store/tradein";
import types from "@/seller/store/types";

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  modules: {
    master: master,
    sales: sales,
    stock: stock,
    seller: seller,
    tradein: tradein,
    types: types,
  },
  plugins: [createPersistedState()]
})

export default store
