import Vue from 'vue'
import VueRouter from 'vue-router'
import routesMaster from "@/master/router";
import routesSeller from "@/seller/router";
import routesSales from "@/sales/router";

Vue.use(VueRouter)

let routes = []

routes.push(...routesMaster)
routes.push(...routesSeller)
routes.push(...routesSales)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
