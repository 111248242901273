
export function coalesce() {
    let len = arguments.length;
    for (let i=0; i<len; i++) {
        if (arguments[i] !== null && arguments[i] !== undefined) {
            return arguments[i];
        }
    }
    return null;
}


export function nil(v) {
    return coalesce(v, '');
}

export function noneInt(v) {
    if(v===undefined||v===null||v<=0) return true;
    return false;
}

export function noneStr(v) {
    if(v===undefined||v===null||v==="") return true;
    return false;
}

export function nilNumber(v) {
    var n_v = v;
    if(n_v!==undefined && n_v!==null && (n_v + "").trim()==="") n_v = null;
    return coalesce(n_v, '0');
}

export function renderDateRange(item, prepend) {
    if(item===undefined || item===null || item.trim() === "" || item.trim().length !== 6) return "";
    return prepend + ' ' + item.substr(4,2) + "/" +  item.substr(0,4);
}


export function daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
}


export function generateUUID() {
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
        d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export const isValidMobilePhone = (mobile) => {
    if(!mobile){
        return false
    }

    // it needs to be in next format: +32486121212 or +32 486 12 12 12

    /*
    ^           # Assert position at the beginning of the string.
    \+          # Match a literal "+" character.
    (?:         # Group but don't capture:
        [0-9]   #   Match a digit.
        \x20    #   Match a space character
        ?       #     between zero and one time.
    )           # End the noncapturing group.
    {10,14}     #   Repeat the group between 10 and 14 times.
        [0-9]   # Match a digit.
        $       # Assert position at the end of the string.
    */
    const phoneRegex = /^\+(?:[0-9] ?){10,14}[0-9]+$/;
    return phoneRegex.test(mobile);
}

export const isValidEmail = value => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value)
}

export const isValidEUVAT = value => {
    if(!value)
        return
    const cleanValue = value.replace(/[. ,:-]+/g, '');
    // https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s21.html
    return /^(BE)?0[0-9]{9}$/i.test(cleanValue) || /^(DE)?[0-9]{9}$/i.test(cleanValue) || /^(LU)?[0-9]{8}$/i.test(cleanValue) || /^(NL)?[0-9]{9}B[0-9]{2}$/i.test(cleanValue)

}
