import VueExcelXlsx from "vue-excel-xlsx";
import Vue from 'vue';
// eslint-disable-next-line no-unused-vars
import './master/plugins/axios';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import "@/assets/fontawesome/css/fontawesome.all.min.css";
import GetTextPlugin from 'vue-gettext';
import translations from './translations';
import Layout from '@/master/layouts/Layout';
import Gravatar from 'vue-gravatar';
import moment from 'moment';
import numeral from 'numeral';
import lodash from 'lodash';
import 'numeral/locales/fr';
import "@/assets/styles/main.css";
import VTooltip from 'v-tooltip';
import JQuery from 'jquery';
import {Plugin} from 'vue-fragment';
import VueTailwind from 'vue-tailwind';
import vueTailwindSettings from "@/components/config/vue-tailwind";
import VGrid from "@revolist/vue-datagrid";
import {nil, noneStr, noneInt} from '@/common';


Vue.component('v-gravatar', Gravatar);
Vue.component('Layout', Layout)
Vue.component('v-grid', VGrid)

Vue.mixin({
    methods: {
        noneStr: noneStr,
        noneInt: noneInt,
        nil: nil
    },
})

Vue.use(GetTextPlugin, {
    availableLanguages: {
        en: 'English',
        nl: 'Nederlands',
        fr: 'Français',
        de: 'Deutsch'
    },
    defaultLanguage: 'en',
    languageVmMixin: {
        computed: {
            currentKebabCase: function () {
                return this.current.toLowerCase().replace('_', '-')
            },
        },
    },
    translations: translations,
    silent: true,
})

Vue.prototype.moment = moment;
Vue.use(VueExcelXlsx);

numeral.locale('fr');
Vue.prototype.numeral = numeral;
Vue.prototype._ = lodash;

window.$ = JQuery


/****/
Vue.use(VueTailwind, vueTailwindSettings);
/****/

Vue.config.productionTip = false;
Vue.use(VTooltip);
Vue.use(Plugin);

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
