const routesMaster = [
    {path: '/', name: 'Home', component: () => import('@/master/views/Home.vue')},
    {path: '/login', name: 'Login', component: () => import('@/master/views/Login.vue'), meta: {app: 'master', layout: 'LayoutLogin'}},
    {path: '/login-authenticator', name: 'LoginAuthenticator', component: () => import('@/master/views/LoginAuthenticator.vue'), meta: {app: 'master', layout: 'LayoutLogin'}},
    {path: '/login-company', name: 'LoginCompany', component: () => import('@/master/views/LoginCompany.vue'), meta: {app: 'master', layout: 'LayoutLogin'}},
    {path: '/login-unit', name: 'LoginUnit', component: () => import('@/master/views/LoginUnit.vue'), meta: {app: 'master', layout: 'LayoutLogin'}},
    {path: '/login-brand', name: 'LoginBrand', component: () => import('@/master/views/LoginBrand.vue'), meta: {app: 'master', layout: 'LayoutLogin'}},
    {
        path: '/main',
        name: 'Main',
        components: {},
        meta: {
            app: 'master',
            layout: 'LayoutMain'
        }
    },
    {
        path: '/master/home/',
        name: 'Main',
        components: {
            default: () => import('@/master/views/Home/Main.vue'),
            detail: () => import('@/master/views/Home/Detail.vue'),
            panel: () => import('@/master/views/Home/Panel.vue')
        },
        meta: {app: 'master', layout: 'LayoutMainFull'}
    },
    {
        path: '/master/:part',
        name: 'Main',
        components: {
            default: () => import('@/master/views/Home/Main.vue'),
            detail: () => import('@/master/views/Home/Detail.vue'),
            panel: () => import('@/master/views/Home/Panel.vue')
        },
        meta: {
            app: 'master',
            layout: 'LayoutMain'
        }
    },
    {
        path: '/company/:part',
        name: 'Main',
        components: {
            default: () => import('@/master/views/Home/Main.vue'),
            detail: () => import('@/master/views/Home/Detail.vue'),
            panel: () => import('@/master/views/Home/Panel.vue')
        },
        meta: {
            app: 'master',
            layout: 'LayoutMain'
        }
    },
    {
        path: '/module/:part',
        name: 'Main',
        components: {
            default: () => import('@/master/views/Home/Main.vue'),
            detail: () => import('@/master/views/Home/Detail.vue'),
            panel: () => import('@/master/views/Home/Panel.vue')
        },
        meta: {app: 'master', layout: 'LayoutMainFull'}
    },
]

export default routesMaster;
