import axios from "axios";
import store from '@/store';


export default {
    namespaced: true,
    state: () => ({
        lead: null,
        leadId: null,
        loadingLead: false,
        menu: 'start'
    }),
    getters: {
        loading: state => {
            return state.loadingLead;
        },
        lead: state => {
            return state.lead;
        },
        menu: state => {
            return state.menu;
        }
    },
    actions: {
    },
    mutations: {
        setMenu: (state, data) => {
            state.menu = data;
        },
        setLoading: (state, data) => {
          state.loadingLead = data;
        },
        openItem: (state, data) => {
            state.loadingLead = true;
            state.leadId = data;
            store.commit('master/openDetail');
            axios.get('/manager/seller/data/request/' + state.leadId + '/').then(response => {
                state.lead = response.data;
                state.loadingLead = false;
            }).catch(function (error) {
                console.log(error);
            });
        },
    }
}