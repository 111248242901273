const routesSeller = [
    {
        path: '/seller/takeover',
        name: 'Main',
        components: {
            default: () => import('@/seller/views/tradein/Main.vue'),
            detail: () => import('@/seller/views/tradein/Detail.vue'),
            panel: () => import('@/seller/views/tradein/Panel.vue')
        },
        meta: {app: 'master', layout: 'LayoutMain'}
    },
    {
        path: '/seller/dashboard',
        name: 'Main',
        components: {
            default: () => import('@/seller/views/dashboard/Main.vue'),
            detail: () => import('@/seller/views/dashboard/Detail.vue')
        },
        meta: {app: 'master', layout: 'LayoutMainFull'}
    },
    {
        path: '/seller/:part',
        name: 'Main',
        components: {
            default: () => import('@/master/views/Home/Main.vue'),
            detail: () => import('@/master/views/Home/Detail.vue'),
            panel: () => import('@/master/views/Home/Panel.vue')
        },
        meta: {app: 'master', layout: 'LayoutMain'}
    },
]

export default routesSeller;

